@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    background-color: #f9fafc;
}

.headerBar {
    @apply w-full h-14 bg-white mb-3 shadow-lg rounded items-center font-medium text-slate-700 flex justify-between items-center px-5
}

.btn-primary {
    @apply w-min px-2 py-1 rounded-md bg-blue-500 hover:cursor-pointer hover:bg-blue-600 text-white flex flex-row items-center
}

.btn-secondary {
    @apply bg-white text-blue-600 border border-blue-600 px-4 py-2 rounded font-medium text-sm hover:bg-blue-600 hover:text-white w-min
}

.btn-delete {
    @apply bg-white text-rose-700 border border-rose-700 px-4 py-2 rounded font-medium text-sm hover:bg-rose-700 hover:text-white w-min
}

.btn-download {
    @apply w-min px-2 py-1 rounded-md bg-green-500 hover:cursor-pointer hover:bg-green-600 text-white flex flex-row items-center
}

.file {
    @apply block text-sm text-gray-500 file:me-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-600 file:disabled:opacity-50 file:disabled:pointer-events-none file:cursor-pointer
}